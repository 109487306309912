import React from "react";
import Link from "gatsby-link";

import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import JobHeader from "../../components/job-header.js";

const CappfinityPage = () => (
  <Layout>
    <JobHeader
      image={"/img/cappfinity-header3.jpg"}
      title="Cappfinity"
      subtitle="Website development and design support"
      text="Web Development / Web Design  / React / Gatsby / GraphQL"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            My role on this project was to develop a new site for Cappfinity. I
            supported the heads of design in the design stage and then worked on
            my own to develop the site. I used React and Gatsby to build the
            site and integrated Netlify hosting and Netfliy CMS. The site
            includes a blog which uses GraphQl to query data.{" "}
          </em>
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/cappfinity/cappfinity-screens2.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/cappfinity/cappfinity-screens.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/cappfinity/cappfinity-screens1.jpg" />
      </Col>
    </Row>

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/perfumeshop-card.jpg"
      title2="The Perfume Shop"
      text2="A fragrance specialist based in the UK"
      url2="/perfume-shop/"
      image3="/img/spread-co-card.jpg"
      title3="Spread Co"
      text3="A financial spread betting company"
      url3="/spread-co/"
    />
  </Layout>
);

export default CappfinityPage;
